import { StaticQuery, graphql, Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { mq, colors } from "../utils/presets"

const MenuList = styled.ul`
  display: none;
  ${mq.xl} {
    display: block;
    list-style: none;
    display: flex;
    flex-flow: row;
    position: absolute;
    /* top: 2.5rem; */
    right: 1rem;
    top: 1.45rem;
    margin: 0;
    li {
      display: block;
      margin: 0;
      &:last-child {
        a {
          padding-right: 0;
        }
      }
      a {
        padding: 1rem;
        display: block;
        color: ${colors.black};
        text-decoration: none;
        font-family: "Leelawadee", system-ui, -apple-system, BlinkMacSystemFont,
          "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
          "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
          "Noto Color Emoji";
        text-transform: uppercase;
        font-size: 0.8rem;
        letter-spacing: 0.5px;
      }
    }
  }
`

const MenuItems = (isFrontPage) => (
  <StaticQuery
    query={graphql`
      query {
        wpMenu(name: { eq: "Hauptmenu" }) {
          menuItems {
            nodes {
              url
              label
            }
          }
        }
      }
    `}
    render={(data) => (
      <MenuList>
        <li key="home">
          <Link
            to="/"
            activeStyle={{
              fontWeight: "bold",
            }}
          >
            Projekte
          </Link>
        </li>
        {data.wpMenu.menuItems.nodes.map((item) => (
          <li key={item.url}>
            <Link
              to={`${item.url}`}
              activeStyle={{
                fontWeight: "bold",
              }}
            >
              {item.label}
            </Link>
          </li>
        ))}
      </MenuList>
    )}
  />
)

export default MenuItems
