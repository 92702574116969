import { StaticQuery, graphql, Link } from "gatsby"
import React from "react"
import { mq, colors } from "../utils/presets"
import styled from "styled-components"
import logo from "../images/rsplogo.svg"
import MenuItems from "../components/menuitems"

const Logo = styled.img`
  min-width: 60px;
  width: 60px;
  height: 60px;
`

const Wrapper = styled.div`
  display: flex;
  flex-flow: row;
  padding: 1rem;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  width: 100%;
  a {
    width: 100%;
    flex-flow: row;
    flex: 0 1 auto;
    line-height: 0;
    display: flex;
    text-decoration: none;
  }
  img {
    flex: 0 1 auto;
  }
  h1 {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
  }
  .logotitle {
    font-size: 2em;
    margin: 0.67em 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.45rem;
    color: inherit;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-weight: normal;
    text-rendering: optimizeLegibility;
    font-size: 2.25rem;
    line-height: 1.45;
    letter-spacing: 0.5px;
    color: #4a4a4a;
    flex: 1 0 auto;
    font-size: 0.8rem;
    font-weight: normal;
    border-bottom: 1px solid ${colors.black};
    text-align: right;
    align-self: center;
    margin-bottom: 5px;
    margin-left: 0.5rem;
    text-transform: uppercase;
  }
  ${mq.xl} {
    .logotitle {
      text-align: left;
      margin-left: 1rem;
    }
  }
`

const Header = ({ isFrontPage, title }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <Wrapper>
        <Link to="/">
          {!!isFrontPage ? (
            <>
              <h1>{data.site.siteMetadata.title}</h1>
              <Logo src={logo} alt={data.site.siteMetadata.title} />
            </>
          ) : (
            <Logo src={logo} alt={data.site.siteMetadata.title} />
          )}
          <div className="logotitle">Architektur + Stadtplanung</div>
        </Link>
        <MenuItems isFrontpage={isFrontPage} />
      </Wrapper>
    )}
  />
)

export default Header
