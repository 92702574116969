import React from "react"
import { Link } from "gatsby"
import logo from "../images/rsplogo.svg"
import styled from "styled-components"
import { mq, colors } from "../utils/presets"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWarehouseAlt } from "@fortawesome/pro-light-svg-icons"
import { faFileUser } from "@fortawesome/pro-light-svg-icons"
import { faPhone } from "@fortawesome/pro-light-svg-icons"
import { faMapMarkerAlt } from "@fortawesome/pro-light-svg-icons"

const FooterWrap = styled.div`
  width: 100%;
  background: ${colors.black};
  ${mq.tablet} {
    margin-top: 3rem;
  }
`

const FooterInner = styled.div`
  z-index: 1;
  max-width: 1600px;
  margin: 0 auto;
  color: white;
  font-family: "Leelawadee", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  padding: 2rem 1rem 0;
  display: flex;
  flex-flow: row wrap;
  font-size: 0.77rem;
  /* justify-content: space-between; */
  justify-content: flex-start;
  ${mq.tablet} {
    justify-content: flex-start;
  }
  ${mq.xl} {
    max-width: 1074px;
    margin: 0 auto;
    height: 180px;
    margin-bottom: -3rem !important;
  }
  h2 {
    font-size: 1rem;
    margin-bottom: 0.4rem;
    color: white;
    text-transform: uppercase;
    font-weight: 400;
  }
  a {
    color: white;
    text-decoration: none;
  }
  p {
    margin-bottom: 0;
  }
  .flex {
    flex: 0 1 auto;
    ${mq.tablet} {
      padding-bottom: 2rem;
      margin-right: 3rem;
      margin-bottom: -3.5rem;
      padding-bottom: 0;
    }
    a {
      text-decoration: underline;
      svg {
        margin-left: 0.4rem;
      }
    }
    h2 {
      a {
        text-decoration: none;
      }
    }
  }
  .anschrift {
    margin-right: 1rem;
  }
  ${mq.tablet} {
    .anschrift {
      margin-right: 0;
    }
  }
  .jobs {
    display: none;
  }
  .projekte {
    display: none;
    margin-right: 2.5rem;
  }
  ${mq.xl} {
    .jobs {
      display: block;
    }
    .projekte {
      display: block;
    }
    .faicon {
      display: none;
    }
  }
`

const Bottomwrap = styled.div`
  z-index: -1;
  text-align: center;
  display: flex;
  flex-flow: row;
  margin: 0;
  color: white;
  padding: 1rem 1rem 5rem;
  align-items: bottom;
  ${mq.xl} {
    padding-bottom: 0.7rem;
  }
  .left {
    text-align: left;
    width: 80%;
    flex: 1 0 auto;
    align-self: flex-end;
    .title {
      font-size: 0.8rem;
      border-bottom: 1px solid white;
      margin-right: 0.5rem;
      margin-bottom: 0rem;
      line-height: 1.2;
    }
    .copy {
      font-size: 0.6rem;
      margin-bottom: 0.76rem;
      padding-right: 0.45rem;
      line-height: 1.7;
    }
    ${mq.tablet} {
      text-align: right;
      padding-right: 1rem;
      margin-bottom: 0.7rem;
      .title {
        font-size: 0.8rem;
        border-bottom: 1px solid white;
        margin-right: 0.5rem;
        margin-bottom: 0rem;
        line-height: 1.3;
      }
      .copy {
        font-size: 0.6rem;
        margin-bottom: 0.53rem;
        padding-right: 0.45rem;
        line-height: 1.8;
      }
    }
  }
  .right {
    align-self: flex-end;
    width: 20%;
    flex: 1 0 auto;
    width: 60px;
    height: 60px;
    max-height: 70px;
    max-width: 70px;
    ${mq.tablet} {
      width: 90px;
      height: 90px;
      max-height: 90px;
      max-width: 90px;
    }
  }
`

const Footer = () => (
  <FooterWrap>
    <FooterInner>
      <Link to="/" className="projekte">
        <div className="flex">
          <h2>
            <FontAwesomeIcon className="faicon" icon={faWarehouseAlt} />{" "}
            Projekte
          </h2>
          <p>
            Architektur
            <br />
            Stadtplanung
            <br />
            Wettbewerbe
          </p>
        </div>
      </Link>

      <Link to="/jobs" className="jobs">
        <div className="flex">
          <h2>
            <FontAwesomeIcon className="faicon" icon={faFileUser} /> Jobs
          </h2>
          <p>
            Architekt
            <br />
            Bautechniker
            <br />
            Stadtplaner
          </p>
        </div>
      </Link>

      <Link to="/kontakt" className="anschrift">
        <div className="flex">
          <h2>
            <FontAwesomeIcon className="faicon" icon={faMapMarkerAlt} />{" "}
            Anschrift
          </h2>
          <p>
            RSP Architektur +<br />
            Stadtplanung GmbH
            <br />
            Rosestraße 24
            <br />
            95448 Bayreuth
          </p>
          <br />
        </div>
      </Link>

      <div className="flex kontakt">
        <h2>
          <FontAwesomeIcon className="faicon" icon={faPhone} /> Kontakt
        </h2>
        Tel: <a href="tel:+49921-2305804-0">0921 / 2305804-0</a>
        <br />
        Fax: <a href="tel:+499217645020">0921 / 2305804-20</a>
        <br />
        <a href="mailto:info@rsp-architektur.de">info@rsp-architektur.de</a>
        <br />
        <Link to="/impressum" title="Impressum">
          Impressum
        </Link>
        ,{" "}
        <Link to="/datenschutz" title="Datenschutz">
          Datenschutz
        </Link>
      </div>
    </FooterInner>

    <Bottomwrap>
      <div className="left">
        <p className="title">ARCHITEKTUR + STADTPLANUNG</p>
        <p className="copy">Copyright © RSP Architektur + Stadtplanung</p>
      </div>
      <Link to="/">
        <img className="right" src={logo} alt="RSP Architektur" />
      </Link>
    </Bottomwrap>
  </FooterWrap>
)

export default Footer
