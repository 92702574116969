import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({
  image,
  description,
  meta,
  title,
  lang,
  jobposting,
  modified,
  jobtype,
  date,
  schema,
  uri,
  seoTitle,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            address {
              streetAddress
              addressLocality
              addressRegion
              postalCode
              addressCountry
            }
            siteUrl
            social {
              twitter
            }
          }
        }
        wp {
          generalSettings {
            title
            description
          }
        }
      }
    `
  )

  // console.log(title)

  const address = site.siteMetadata.address

  const nodashtitle = seoTitle.replace(/\u00AD/g, "")

  const Url = site.siteMetadata.siteUrl
  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const metaImageSrc =
    image && image.src ? `${site.siteMetadata.siteUrl}${image.src}` : null

  const schemaLocalBusiness = {
    "@context": "https://schema.org",
    "@type": "HomeAndConstructionBusiness",
    // image: [
    //   "https://example.com/photos/1x1/photo.jpg",
    //   "https://example.com/photos/4x3/photo.jpg",
    //   "https://example.com/photos/16x9/photo.jpg",
    // ],
    name: defaultTitle,
    address: {
      "@type": "PostalAddress",
      streetAddress: address.streetAddress,
      addressLocality: address.addressLocality,
      addressRegion: address.addressRegion,
      postalCode: address.postalCode,
      addressCountry: address.addressCountry,
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: 49.950008,
      longitude: 11.58655,
    },
    url: "https://www.rsp-architektur.de",
    telephone: "+4992123058040",
  }

  const schemaBreadcrumbs = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: title,
        item: `${Url}${uri}`,
      },
    ],
  }

  let schemaJobPosting = undefined

  if (jobposting) {
    schemaJobPosting = {
      "@context": "https://schema.org/",
      "@type": "JobPosting",
      title: title,
      description: description,
      identifier: {
        "@type": "PropertyValue",
        name: "RSP Architektur + Stadtplanung",
      },
      datePosted: modified,
      employmentType: jobtype,
      hiringOrganization: {
        "@type": "Organization",
        name: "RSP Architektur + Stadtplanung GmbH",
        sameAs: "https://www.rsp-architektur.de",
      },
      jobLocation: {
        "@type": "Place",
        address: {
          "@type": "PostalAddress",
          streetAddress: "Rosestraße 24",
          addressLocality: "Bayreuth",
          addressRegion: "Bayern",
          postalCode: "95448",
          addressCountry: "DE",
        },
      },
    }
  }

  const schemaData = {
    schemaLocalBusiness,
    schemaBreadcrumbs,
    schemaJobPosting,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={site.siteMetadata.title}
      titleTemplate={`${nodashtitle}`}
      meta={[
        {
          name: `image`,
          content: metaImageSrc,
        },
        {
          name: `description`,
          content: metaDescription,
        },

        {
          property: `og:locale`,
          content: "de_DE",
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: Url,
        },

        {
          name: `twitter:card`,
          content: "summary_large_image",
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.social.twitter,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          metaImageSrc
            ? [
              {
                property: "og:image",
                content: metaImageSrc,
              },
              {
                property: "og:image:alt",
                content: title,
              },
              {
                property: "og:image:width",
                content: image.width,
              },
              {
                property: "og:image:height",
                content: image.height,
              },
              {
                name: "twitter:card",
                content: "summary_large_image",
              },
            ]
            : [
              {
                name: "twitter:card",
                content: "summary",
              },
            ]
        )
        .concat(
          // handle Secure Image
          metaImageSrc && metaImageSrc.indexOf("https") > -1
            ? [
              {
                property: "twitter:image:secure_url",
                content: metaImageSrc,
              },
              {
                property: "og:image:secure_url",
                content: metaImageSrc,
              },
            ]
            : []
        )
        .concat(meta)}
    >
      {/* {console.log(schemaData)} */}
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
      <script defer data-domain="rsp-architektur.de" src="https://plausible.io/js/script.js"></script>



    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
