/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { mq } from "../utils/presets"
import styled from "styled-components"
import "./layout.scss"
import Header from "./header"
import Footer from "./footer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUsers } from "@fortawesome/pro-light-svg-icons"
import { faWarehouseAlt } from "@fortawesome/pro-light-svg-icons"
import { faFileUser } from "@fortawesome/pro-light-svg-icons"
import { faPhone } from "@fortawesome/pro-light-svg-icons"
import { faListAlt } from "@fortawesome/pro-light-svg-icons"

const FixedBottomMenu = styled.div`
  position: fixed;
  /* z-index: 9999; */
  bottom: 0;
  background: #fefefe;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  font-size: 0.8rem;
  ${mq.xl} {
    display: none;
  }
  ul {
    margin: 0;
    width: 100%;
    display: flex;
    flex-flow: row;
    list-style: none;
    justify-content: space-between;
    padding-bottom: 0.6rem;
    padding-top: 0.2rem;
    li {
      margin: 0;
      flex: 1 0 auto;
      width: 20%;
      text-align: center;
      a {
        display: block;
        padding: 0.5rem 0.2rem 0.2rem;
        text-decoration: none;
        color: #5a5a5a;
        padding-bottom: env(safe-area-inset-bottom);
      }
    }
  }
`

const Main = styled.main``

const Layout = ({ location, children, uri, mobilemenu, isFrontPage }) => {
  const {
    wp: {
      generalSettings: { title },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

  return (
    <>
      <Header isFrontPage={isFrontPage} title={title} />
      <div>
        <Main>{children}</Main>
        {/* <Footer /> */}
        <FixedBottomMenu>
          <ul>
            <li>
              <Link
                to="/"
                activeStyle={{
                  color: "black",
                }}
              >
                <FontAwesomeIcon
                  icon={faWarehouseAlt}
                  size="2x"
                  style={{ width: "25px", height: "25px" }}
                />
                <br />
                Projekte
              </Link>
            </li>
            <li>
              <Link
                to="/leistungen"
                activeStyle={{
                  color: "black",
                }}
              >
                <FontAwesomeIcon
                  icon={faListAlt}
                  size="2x"
                  style={{ width: "25px", height: "25px" }}
                />
                <br />
                Leistungen
              </Link>
            </li>
            <li>
              <Link
                to="/jobs"
                activeStyle={{
                  color: "black",
                }}
              >
                <FontAwesomeIcon
                  icon={faFileUser}
                  size="2x"
                  style={{ width: "25px", height: "25px" }}
                />
                <br />
                Jobs
              </Link>
            </li>
            <li>
              <Link
                to="/team"
                activeStyle={{
                  color: "black",
                }}
              >
                <FontAwesomeIcon
                  icon={faUsers}
                  size="2x"
                  style={{ width: "25px", height: "25px" }}
                />
                <br />
                Team
              </Link>
            </li>
            <li>
              <Link
                to="/kontakt"
                activeStyle={{
                  color: "black",
                }}
              >
                <FontAwesomeIcon
                  icon={faPhone}
                  size="2x"
                  style={{ width: "25px", height: "25px" }}
                />
                <br />
                Kontakt
              </Link>
            </li>
          </ul>
        </FixedBottomMenu>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
